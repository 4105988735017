/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import ptBr from 'date-fns/locale/pt-BR';
import { Container, Row, Col } from "react-grid-system";
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button,
  CardHeader,
  CardBody,
  GeneralInfo,
  LabelInput,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "../../components/TextInput";
import LookupInput from "../../components/LookupInput";
import DateInput from "../../components/DateInput";
import Toast from "../../components/Toast";
import {
  getChavePedido,
} from "../../services/pedidos";
import { useAuth } from "../../contexts/auth";
import { atualizarTicket, criarTicket, getTickets } from "../../services/projetos";
import TableLookups from "../../components/TableLookups";

interface iSalesOrder {
  baixaAutom?: boolean;
}

const Ticket: React.FC<iSalesOrder> = () => {
  const history = useHistory();
  const location: any = useLocation();
  const itemEdicao =
    location.state && location.state.item ? location.state.item : null;
  const { user } = useAuth();
  const novoPedido = location.state && location.state.item ? false : true;
  const [payload, setPayload] = useState<any>({
    name: '',
    descricao: '',
  });
  const [loadings, setLoadings] = useState({
    salvar: false,
  });
  const [messages, setMessages] = useState<any>([]);
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [lookups, setLookups] = useState<any>({});
  const [initialLookups, setInitialLookups] = useState<any>({
    Project: null,
    Sprint: null,
    RespConsultoria: null,
    RespDev: null,
    Status: null,
  });

  useEffect(() => {
    if (itemEdicao) {
      loadInitialState(itemEdicao);
    } else {
      setInitialLookups((oldData: any) => {
        return {
          ...oldData,
          Status: { byId: '6348573732bc9aa395b233ba' }
        };
      })
    }
  }, [itemEdicao]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
    }
  }, [novoPedido]);

  async function loadInitialState(pedido: any, baixa: boolean = false) {
    if (!baixa) {
      setChaveCriacao(pedido.chcriacao);
      await getMessages(pedido.chcriacao);
    }

    setInitialLookups((oldData: any) => {
      return {
        ...oldData,
        Project: { byId: pedido.idProject },
        Sprint: pedido.idSprint ? {byId: pedido.idSprint} : null,
        RespConsultoria: pedido.idRespConsultoria ? {byId: pedido.idRespConsultoria} : null,
        RespDev: pedido.idRespDev ? {byId: pedido.idRespDev} : null,
        Status: pedido.idStatus ? {byId: pedido.idStatus} : null,
        Tipo: pedido.idTipo ? {byId: pedido.idTipo} : null,
      };
    });
  }

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show(
        "Ocorreu um erro ao tentar buscar o número do ticket",
        "error"
      );
    }
  }

  async function getMessages(chcriacao: number) {
    try {
      const result = await getTickets(chcriacao);
      setMessages(result);
    } catch (_err) {
      return Toast.show(
        "Ocorreu um erro ao tentar buscar as mensagens do ticket",
        "error"
      );
    }
  }

  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  }

  function handleChangePayload(field: string, value: any) {
    return setPayload((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings((oldValue) => {
      return { ...oldValue, [field]: value };
    });
  }

  async function handleSalvar() {
    handleChangeLoading("salvar", true);

    if (!payload.name) {
      handleChangeLoading("salvar", false);
      return Toast.show("O campo título é obrigatório", "error");
    }

    if (!payload.descricao) {
      handleChangeLoading("salvar", false);
      return Toast.show("O campo descrição é obrigatório", "error");
    }

    if (!lookups.Tipo) {
      handleChangeLoading("salvar", false);
      return Toast.show("O campo tipo é obrigatório", "error");
    }

    if (!lookups.Project || !lookups.Project.value) {
      handleChangeLoading("salvar", false);
      return Toast.show("O campo projeto é obrigatório", "error");
    }

    try {
      let toSendPayload: any = {
        chcriacao: chaveCriacao,
        idProject: lookups.Project ? lookups.Project.value : null,
        idSprint: lookups.Sprint ? lookups.Sprint.value : null,
        idStatus: lookups.Status ? lookups.Status.value : '6348573732bc9aa395b233ba',
        idRespConsultoria: lookups.RespConsultoria ? lookups.RespConsultoria.value : null,
        idRespDev: lookups.RespDev ? lookups.RespDev.value : null,
        idTipo: lookups.Tipo ? lookups.Tipo.value : null,
        idClasse: '63486342ff3dccf7dd002d4b',
        name: payload.name,
        description: payload.descricao,
        idEnviadoPor: user?.id,
      };
      await criarTicket(toSendPayload);
      Toast.show("Ticket criado com sucesso", "success");
      history.push("/tickets");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  async function handleUpdate() {
    handleChangeLoading("salvar", true);

    if (user && user.idClasse === '61cd2730ad47e0a02eab67d8') {
      if (!payload.name) {
        handleChangeLoading("salvar", false);
        return Toast.show("O campo título é obrigatório", "error");
      }

      if (!payload.descricao) {
        handleChangeLoading("salvar", false);
        return Toast.show("O campo descrição é obrigatório", "error");
      }
    }

    if (!lookups.Project || !lookups.Project.value) {
      handleChangeLoading("salvar", false);
      return Toast.show("O campo projeto é obrigatório", "error");
    }

    try {
      let toSendPayload: any = null
      
      if (user && user.idClasse === '61cd2730ad47e0a02eab67d8') {
        toSendPayload = {
          chcriacao: chaveCriacao,
          idProject: itemEdicao.idProject,
          idSprint: itemEdicao.idSprint,
          idStatus: itemEdicao.idStatus,
          idRespConsultoria: itemEdicao.idRespConsultoria,
          idRespDev: itemEdicao.idRespDev,
          idTipo: itemEdicao.idTipo,
          idClasse: '63486342ff3dccf7dd002d4b',
          name: payload.name,
          description: payload.descricao,
          idEnviadoPor: user?.id,
        };
      } else {
        toSendPayload = {
          chcriacao: chaveCriacao,
          idProject: lookups.Project ? lookups.Project.value : null,
          idSprint: lookups.Sprint ? lookups.Sprint.value : null,
          idStatus: lookups.Status ? lookups.Status.value : null,
          idRespConsultoria: lookups.RespConsultoria ? lookups.RespConsultoria.value : null,
          idRespDev: lookups.RespDev ? lookups.RespDev.value : null,
          idTipo: lookups.Tipo ? lookups.Tipo.value : null,
          idClasse: '63486342ff3dccf7dd002d4b',
          name: payload.name,
          description: payload.descricao,
          idEnviadoPor: user?.id,
        };
      }
      await atualizarTicket(chaveCriacao, toSendPayload);
      Toast.show("Ticket atualizado com sucesso", "success");
      history.push("/tickets");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  };

  async function handleSubmit(e: any) {
    let tipo;
    if (e) {
      e.preventDefault();
      tipo = e.nativeEvent.submitter.name;
    }

    switch (tipo) {
      case "salvar":
        if (itemEdicao) {
          await handleUpdate();
        } else {
          await handleSalvar();
        }
        break;
      default:
        break;
    }
  }

  return (
    <Wrapper>
      <HeaderContent>
        <Title>Ticket N.° {chaveCriacao}</Title>
      </HeaderContent>
      <Row style={{gap: 32}}>
        <Col md={6} style={{padding: 0}}>
        {user && user.idClasse === '61cd2730ad47e0a02eab67d8' && itemEdicao && (
          <Content id="custom-form" onSubmit={handleSubmit}>
            <Container fluid style={{ width: "100%" }}>
              <Row>Informações gerais</Row>
              <GeneralInfo style={{fontSize: 14}}>
                <table>
                  <tbody>
                    <tr>
                      <td>Projeto</td>
                      <td>
                        <TableLookups
                          disabled
                          initialState={initialLookups.Project}
                          endpoint="projetos"
                          field="name"
                          labelField="name"
                          filters={{
                            classe: "Projetos",
                            idPessoa: user?.id,
                          }}
                          multi={false}
                          onChange={(options) =>
                            handleChangeLookup("Project", options)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Consultor(a)</td>
                      <td>
                        {messages && messages[0] && messages[0].RespConsultoria ? messages[0].RespConsultoria.nome : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Desenvolvedor(a)</td>
                      <td>
                        {messages && messages[0] && messages[0].RespDev ? messages[0].RespDev.nome : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Sprint</td>
                      <td>
                        {messages && messages[0] && messages[0].Sprint ? messages[0].Sprint.nome : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Tipo</td>
                      <td>
                        {messages && messages[0] && messages[0].Tipo ? messages[0].Tipo.nome : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {messages && messages[0] && messages[0].Status ? messages[0].Status.nome : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </GeneralInfo>
            </Container>
          </Content>
        )}
        {user && user.idClasse !== '61cd2730ad47e0a02eab67d8' && (
          <Content id="custom-form" onSubmit={handleSubmit}>
            <Container fluid style={{ width: "100%" }}>
              <Row>Cabeçalho</Row>
              <br />
              <Row></Row>
              <Row style={{marginBottom: 8, gap: 12}}>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.Project}
                    label="Projeto"
                    endpoint="projetos"
                    field="name"
                    labelField="name"
                    filters={{
                      classe: "Projetos",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Project", options)
                    }
                  />
                </Col>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.RespConsultoria}
                    label="Consultor"
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Entidades',
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("RespConsultoria", options)
                    }
                  />
                </Col>
              </Row>
              <Row style={{marginBottom: 8, gap: 12}}>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.RespDev}
                    label="Desenvolvedor"
                    endpoint="entidades"
                    field="nome"
                    filters={{
                      classe: 'Entidades',
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("RespDev", options)
                    }
                  />
                </Col>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.Sprint}
                    label="Sprint"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: 'Sprints'
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Sprint", options)
                    }
                  />
                </Col>
              </Row>
              <Row style={{marginBottom: 8, gap: 12}}>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.Tipo}
                    label="Tipo"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: 'Tipos de Ticket'
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Tipo", options)
                    }
                  />
                </Col>
                <Col md={5.8} style={{padding: 0}}>
                  <LookupInput
                    initialState={initialLookups.Status}
                    label="Status"
                    endpoint="tabela"
                    field="nome"
                    filters={{
                      classe: 'Status de Tarefa'
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Status", options)
                    }
                  />
                </Col>
              </Row>
              {itemEdicao && <Row>
                <Button
                  form="custom-form"
                  type="submit"
                  id="salvar"
                  name="salvar"
                  disabled={loadings.salvar}
                >
                  {loadings.salvar ? "Salvando..." : "Salvar"}
                </Button>
              </Row>}
            </Container>
          </Content>
        )}
        <br />
        <Content id="custom-form" onSubmit={handleSubmit}>
          <Container fluid style={{ width: "100%" }}>
            {user && user.idClasse === '61cd2730ad47e0a02eab67d8' && !itemEdicao &&(
              <Row style={{marginBottom: 8,}}>
                <Col style={{padding: 0,}}>
                  <LabelInput>Projeto</LabelInput>
                  <TableLookups
                    initialState={initialLookups.Project}
                    endpoint="projetos"
                    field="name"
                    labelField="name"
                    filters={{
                      classe: "Projetos",
                      idPessoa: user?.id,
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Project", options)
                    }
                  />
                </Col>
              </Row>
            )}
            {user && user.idClasse === '61cd2730ad47e0a02eab67d8' && !itemEdicao &&(
              <Row style={{marginBottom: 8,}}>
                <Col style={{padding: 0,}}>
                  <LabelInput>Tipo</LabelInput>
                  <TableLookups
                    initialState={initialLookups.Tipo}
                    endpoint="tabela"
                    field="nome"
                    labelField="nome"
                    filters={{
                      classe: "Tipos de Ticket",
                    }}
                    multi={false}
                    onChange={(options) =>
                      handleChangeLookup("Tipo", options)
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <TextInput
                label="Título"
                id="name"
                value={payload.name}
                onChange={({ target: { value } }) =>
                  handleChangePayload("name", value)
                }
              />
            </Row>
            <Row style={{marginBottom: 8,}}>
              <TextInput
                label="Descrição"
                id="descricao"
                type="textarea"
                value={payload.descricao}
                onChange={({ target: { value } }) =>
                  handleChangePayload("descricao", value)
                }
              />
            </Row>
            <Row>
              <Button
                form="custom-form"
                type="submit"
                id="salvar"
                name="salvar"
                disabled={loadings.salvar}
              >
                {loadings.salvar ? "Salvando..." : "Salvar ticket"}
              </Button>
            </Row>
          </Container>
        </Content>
        </Col>
        <Col md={5} style={{padding: 0}}>
          {messages && messages.map((_it: any, index: number) => {
            if (_it.idEnviadoPor === user?.id) {
              return (
                <Content style={{ marginBottom: 16, backgroundColor: 'var(--secondary-lighter)' }}>
            <Container fluid style={{ width: "100%" }}>
              <CardHeader>
                <p>{_it.EnviadoPor && _it.EnviadoPor.nome}</p>
                <span>{formatDistanceToNow(_it.criadoEm ? new Date(_it.criadoEm) : new Date(), { locale: ptBr })}</span>
              </CardHeader>
              <CardBody>
                <p>{_it.name}</p>
                <p>{_it.description}</p>
              </CardBody>
            </Container>
          </Content>);
            } else {
              return (
                <Content style={{ marginBottom: 16 }}>
              <Container fluid style={{ width: "100%" }}>
                <CardHeader>
                  <p>{_it.EnviadoPor && _it.EnviadoPor.nome}</p>
                  <span>{formatDistanceToNow(_it.criadoEm ? new Date(_it.criadoEm) : new Date(), { locale: ptBr })}</span>
                </CardHeader>
                <CardBody>
                  <p>{_it.name}</p>
                  <p>{_it.description}</p>
                </CardBody>
              </Container>
            </Content>
              )
            }
          })}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Ticket;
