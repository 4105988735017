import styled from 'styled-components';
import ButtonLayout from '../../components/Button';

export const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .labelNumero {
    font-size: 12px !important;
  }
`;

export const Title = styled.p`
  font-size: 24px;

  & span {
    font-size: 12px;
    color: var(--muted);
  }
`;

export const Content = styled.form`
  background-color: var(--white);
  width: auto;
  height: auto;
  padding: 16px;

  border-radius: 2px;
  border: 1px solid var(--opacity-background);

  h1 {
    width: 100%;
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: 200;
    margin-bottom: 10px;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const ContentColumnLeft = styled.div`
  width: 60%;
`;

export const ContentColumnRight = styled.div`
  width: 40%;
  height: auto;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #d7d434;
  border-radius: 5px;
  padding: 0 5px;
  box-shadow: 2px 1px 1px #aaa;

  label {
    color: #5997da;
    font-size: 12px;
  }

  textarea {
    margin-top: 30px;
    background-color: #d7d434;
    height: 80%;
    width: 100%;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
  margin: 16px 0 8px 0;
  gap: 16px;
`;

export const ActionsContainer = styled.div`
  width: auto;
  display: flex;
  gap: 16px;

  & button {
    width: auto;
  }

  @media (max-width: 559px) {
    flex-direction: column;
    width: 100%;
    margin-top: 8px;

    & button {
      width: 100%;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 559px) {
    flex-direction: column;
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 14px;
  padding: 10px;
`;

export const Label = styled.label`
  font-size: 12px;
  color: var(--primary-text-color);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    font-size: 12px;
  }
`;

export const CardBody = styled.div`
  margin-top: 16px;
`;

export const GeneralInfo = styled.div`
  margin-top: 8px;
  & table {
    margin-left: -15px;

    & td:first-child {
      width: 150px;
      font-weight: 600;
    }

    & td:nth-child(2) {
      padding: 2px;
    }
  }
`;

export const LabelInput = styled.label<any>`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4px;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-overflow: none;
  color: ${props => props.error ? 'red' : 'auto'};
  font-weight: ${props => props.bold ? '500': '300'};

  & span {
    color: red;
  }
`
;