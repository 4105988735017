import provider from "./config";

export const criarTicket = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/tarefas/', payload);
  
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.message) {
        throw Error(err.response.data.message);
      }
      
      throw Error("Ocorreu um erro ao tentar criar o ticket");
    }
  }

export const atualizarTicket = async (chcriacao: number, payload: any): Promise<any> => {
    try {
      const response = await provider.put(`/tarefas/${chcriacao}`, payload);
  
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.message) {
        throw Error(err.response.data.message);
      }
      
      throw Error("Ocorreu um erro ao tentar atualizar o ticket");
    }
  }

export const getTickets = async (chcriacao: number): Promise<any> => {
    try {
      const response = await provider.get('/tarefas/', {
        params: {
          chcriacao,
        }
      });
  
      return response.data;
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.message) {
        throw Error(err.response.data.message);
      }
      
      throw Error("Ocorreu um erro ao tentar buscar as mensagens do ticket");
    }
  }