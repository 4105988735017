import { IconType } from 'react-icons';
import { IoHome, IoWallet, IoPerson, IoCart, IoStorefront, IoTicket } from 'react-icons/io5';
import { FaCoins, FaMoneyCheckAlt, FaTruck } from 'react-icons/fa';
import { RiFileList3Fill, RiBuilding4Fill, RiTaskFill } from 'react-icons/ri';
import { GiHandTruck, GiReceiveMoney } from 'react-icons/gi';

export interface IMenuOption {
  id: string;
  name: string;
  icon?: IconType;
  children?: Array<IMenuOption>;
  disabled: boolean;
  order: number;
  path?: string;
}

export const menu: Array<IMenuOption> = [
  {
    id: '5m431l2mk42m',
    order: 1,
    name: 'Home',
    icon: IoHome,
    path: '/',
    disabled: false,
  },
  {
    id: '4k51m2lk24ml2',
    name: 'Admin',
    order: 2,
    icon: IoPerson,
    disabled: false,
    children: [
      {
        id: '6mldsa98a7sgby89',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          // {
          //   id: '6pm543ekmk2da',
          //   name: 'Tipos de Documentos',
          //   path: '/tipos-de-documentos',
          //   disabled: false,
          //   order: 5,
          // },
          // {
          //   id: '6pm543ekmk2dadsdaee2a',
          //   name: 'Tipos de Frete',
          //   path: '/tipos-de-frete',
          //   disabled: false,
          //   order: 6,
          // },
          {
            id: 'nF2htCZMDnHQ1',
            name: 'Entidades',
            path: '/entidades',
            disabled: false,
            order: 1,
          },
          // {
          //   id: 'nF2htCZMDnHQ2',
          //   name: 'Recursos',
          //   path: '/recursos',
          //   disabled: false,
          //   order: 4,
          // },
          // {
          //   id: 'nF2htCZMDnHQ3',
          //   name: 'Unidades Federativas',
          //   path: '/unidades-federativas',
          //   disabled: false,
          //   order: 8,
          // },
          // {
          //   id: 'nF2htCZMDnHJJ',
          //   name: 'Municípios',
          //   path: '/municipios',
          //   disabled: false,
          //   order: 2,
          // },
          // {
          //   id: 'nF2htCZMDnHQ4',
          //   name: 'Unidades de Medidas',
          //   path: '/unidades-de-medidas',
          //   disabled: false,
          //   order: 7,
          // },
          // {
          //   id: 'nF2htCZMDnHQ4',
          //   name: 'NCM',
          //   path: '/ncm',
          //   disabled: false,
          //   order: 3,
          // },
          // {
          //   id: 'nF2htCZMDnHQFAEUFHAE',
          //   name: 'Feriados',
          //   path: '/feriados',
          //   disabled: false,
          //   order: 9,
          // },
        ]
      },
      {
        id: '6jkn3mklmeklm',
        name: 'Permissões e Grupos',
        disabled: false,
        order: 2,
        path: '/permissoes-e-grupos',
      },
      // {
      //   id: '6jkn3mklmeklo',
      //   name: 'Tabela de Regras',
      //   disabled: false,
      //   order: 3,
      //   children: [
      //     {
      //       id: '6jkn3mklmeklp',
      //       name: 'Regras de Classes',
      //       path: '/regras-de-classe',
      //       disabled: false,
      //       order: 1,
      //     },
      //     {
      //       id: '6jkn3mklmeklaa',
      //       name: 'Regras de Núcleos',
      //       path: '/regras-de-nucleo',
      //       disabled: false,
      //       order: 2,
      //     },
      //     {
      //       id: '6jkn3mklmeklbb',
      //       name: 'Regras de Precificação',
      //       path: '/regras-de-precificacao',
      //       disabled: false,
      //       order: 3,
      //     },
      //   ]
      // },
      {
        id: '6jkn3mklmeklp',
        name: 'Classes Explorer',
        path: '/classes-explorer',
        disabled: false,
        order: 4,
      },
      // {
      //   id: 'n5to4m321okm2fb5g940n',
      //   name: 'Relatórios',
      //   disabled: false,
      //   order: 9,
      //   children: [
      //     {
      //       id: 'n5to4m321okm2fb5g940n12m241om',
      //       name: 'Listagem de Classes',
      //       path: '/listagem-classes',
      //       disabled: false,
      //       order: 1,
      //     }
      //   ]
      // },
    ]
  },
  {
    id: '6m5lk4mlk1m',
    name: 'Compras',
    icon: IoCart,
    disabled: false,
    order: 3,
    children: [
      {
        id: '6mldsa98a7sgby89',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          {
            id: '6pm543ekmk2da',
            name: 'Centro de Custos',
            path: '/centro-de-custo',
            disabled: false,
            order: 1,
          },
          {
            id: '6pm543ekmk2dadasdasdea',
            name: 'Compradores',
            path: '/compradores',
            disabled: false,
            order: 2,
          },
          {
            id: 'n5l4k213mkl23',
            name: 'Depósitos',
            disabled: false,
            order: 3,
            path: '/depositos',
          },
          {
            id: 'n5l4k213mkl24',
            name: 'Estabelecimentos',
            disabled: false,
            order: 4,
            path: '/estabelecimentos',
          },
          {
            id: 'n5l4k213mkl25',
            name: 'Fornecedores',
            disabled: false,
            order: 5,
            path: '/fornecedores',
          },
          {
            id: '12n5124m125kl412m12l',
            name: 'Importar Fornecedores',
            disabled: false,
            order: 6,
            path: '/importar-fornecedores',
          },
          {
            id: '12n5124m125kl437ahe',
            name: 'Importar Produtos',
            disabled: false,
            order: 7,
            path: '/importar-produtos',
          },
          {
            id: 'n5l4k213mkl26',
            name: 'Locais de Escrituração',
            disabled: false,
            order: 8,
            path: '/locais-de-escrituracao',
          },
          {
            id: 'n5l4k213mkl27',
            name: 'Produtos',
            path: '/produtos',
            disabled: false,
            order: 9,
          },
          {
            id: 'n5l4k213mkl28',
            name: 'Serviços',
            disabled: false,
            order: 10,
            path: '/servicos',
          },
        ]
      },
      {
        id: '6mldsa98a7sgby8b',
        name: 'Pedidos de Entrada',
        path: '/pedidos-de-entrada',
        disabled: false,
        order: 2,
      },
      {
        id: '6mldsa98a7sgby8c',
        name: 'Pedidos de Serviço',
        path: '/pedidos-de-compra-de-servico',
        disabled: false,
        order: 3,
      },
      {
        id: '56nt5lr43emaaas',
        name: 'Condições de Pagamento',
        path: '/condicoes-de-pagamento',
        disabled: false,
        order: 4,
      },
      {
        id: '6mldsa98a7sgby8d',
        name: 'Relatórios',
        disabled: false,
        order: 5,
        children: [
          {
            id: '6mldsa98a7sgby8e',
            name: 'Curva ABC',
            disabled: false,
            order: 1,
          },
          {
            id: '6mldsa98a7sgby8f',
            name: 'Pedidos de Entrada',
            path: '/relatorio-entrada',
            disabled: false,
            order: 2,
          },
          {
            id: '6uefjfoqfjowmk21jds',
            name: 'Relatório de Fornecedores',
            path: '/relatorio-fornecedores',
            disabled: false,
            order: 3,
          },
          {
            id: '6uefjfoqfjowmk21jdsresdae3',
            name: 'Relatório de Produtos',
            path: '/relatorio-produtos',
            disabled: false,
            order: 4,
          }
        ]
      },
      {
        id: '6mldsa98a7sgby8g',
        name: 'Baixa de Pedido',
        path: '/baixa-de-pedido',
        disabled: false,
        order: 6,
      },
    ]
  },
  {
    id: '5tkrlk3m1lk',
    name: 'Contábil',
    icon: IoWallet,
    disabled: false,
    order: 4,
    children: [
      {
        id: '5tkrlk3m1ll',
        name: 'Plano de Contas',
        path: '/plano-de-contas',
        disabled: false,
        order: 1,
      },
      {
        id: '5tkrlk3m1lm',
        name: 'Lançamento Contábil',
        path: '/lancamento-contabil',
        disabled: false,
        order: 2,
      },
      {
        id: '5tkrlk3m1ln',
        name: 'Relatórios',
        disabled: false,
        order: 3,
        children: [
          {
            id: '5tkrlk3m1lo',
            disabled: false,
            order: 1,
            name: 'Livro Diário',
          },
          {
            id: '5tkrlk3m1lp',
            disabled: false,
            order: 2,
            name: 'Razão',
          },
        ]
      },
    ]
  },
  {
    id: '5tkrlk3m1bk',
    name: 'Comissionamento',
    icon: FaMoneyCheckAlt,
    disabled: false,
    order: 5,
    children: [
      {
        id: '5tkrlk3m1bl',
        name: 'Cadastro de Vendedores',
        path: '/vendedores/inserir',
        disabled: false,
        order: 1,
      },
      {
        id: '5tkrlk3m1bkm',
        name: 'Fechamento de Comissão',
        disabled: false,
        order: 2,
      },
      {
        id: '5tkrlk3m1bko',
        name: 'Regras de Comissão',
        path: '/regras-de-comissao',
        disabled: false,
        order: 3,
      },
      {
        id: '5tkrlk3m1bn',
        name: 'Relatórios',
        disabled: false,
        order: 4,
        children: [
          {
            id: '5tkrlk3m1bq',
            disabled: false,
            order: 1,
            name: 'Comissões',
          }
        ]
      },
    ]
  },
  {
    id: '5tkrlk3m1jk',
    name: 'Estoque',
    icon: GiHandTruck,
    disabled: false,
    order: 6,
    children: [
      {
        id: '5tkrlk3m1jl',
        name: 'Cadastro',
        disabled: false,
        order: 1,
        children: [
          {
            id: '5tkrlk3m1jm',
            order: 4,
            disabled: false,
            name: 'Lotes',
            path: '/lotes',
          },
          {
            id: '5tkrlk3m1jj21mr2mkel1',
            order: 1,
            disabled: false,
            name: 'Cores',
            path: '/cores',
          },
          {
            id: '5tkrlk3m1jvhrfned',
            order: 8,
            disabled: false,
            name: 'Tamanhos',
            path: '/tamanhos',
          },
          {
            id: '5tkrlk3m1jn',
            order: 7,
            disabled: false,
            name: 'Produtos',
            path: '/produtos',
          },
          {
            id: '5142mktn5421mkl2135',
            order: 9,
            disabled: false,
            name: 'Transportadoras',
            path: '/transportadoras',
          },
          {
            id: '5tkrlk3m1jo',
            order: 2,
            disabled: false,
            name: 'Depósitos',
            path: '/depositos',
          },
          {
            id: '5tkrlk3m1oj',
            order: 6,
            disabled: false,
            name: 'Procedência',
            path: '/procedencia',
          },
        ]
      },
      {
        id: '5tkrlk3m1jp',
        name: 'Transferência entre Depósitos',
        disabled: false,
        order: 2,
        path: '/movimentacao-depositos/inserir',
      },
      {
        id: '5tkrlk3m1jq',
        name: 'Inventário',
        path: '/inventario',
        disabled: false,
        order: 3,
      },
      {
        id: '5tkrlk3m1jr',
        name: 'Relatórios',
        disabled: false,
        order: 4,
        children: [
          {
            id: '5tkrlk3m1js',
            disabled: false,
            order: 2,
            name: 'Saldo de Estoque',
            path: '/saldo-estoque'
          },
          {
            id: '5tkrlk3m1jt',
            disabled: false,
            order: 1,
            name: 'Extrato',
            path: '/extrato-estoque'
          },
        ]
      },
      {
        id: '5tkrlk3m1js',
        name: 'Requisições',
        path: '/requisicoes',
        disabled: false,
        order: 5,
      },
    ]
  },
  {
    id: '6n4klm3dlka',
    name: 'Financeiro',
    icon: FaCoins,
    disabled: false,
    order: 7,
    children: [
      {
        id: '6n4klm3dlkb',
        name: 'Cadastro',
        disabled: false,
        order: 1,
        children: [
          {
            id: '6n4klm3dlkc',
            order: 4,
            disabled: false,
            name: 'Centros de Custo',
            path: '/centro-de-custo'
          },
          {
            id: '6n4klm3dlkdm2lk1m421kl',
            order: 5,
            disabled: false,
            name: 'Despesas',
            path: '/despesas'
          },
          {
            id: '6n4klm3dlkd412lkm3n21',
            order: 7,
            disabled: false,
            name: 'Receitas',
            path: '/receitas'
          },
          {
            id: '6n4klm3dlke',
            order: 6,
            disabled: false,
            name: 'Disponíveis',
            path: '/disponiveis'
          },
          {
            id: '6n4klm3dlkem4e21kfnt31rm2',
            order: 3,
            disabled: false,
            name: 'Carteiras de Cobrança',
            path: '/carteiras-de-cobranca'
          },
          {
            id: '6n4klm3dlketn43o2r',
            order: 2,
            disabled: false,
            name: 'Bandeiras de Cartão',
            path: '/bandeiras-de-cartao'
          },
          {
            id: 'f32yh18dun7hdnjnd',
            order: 1,
            disabled: false,
            name: 'Bancos',
            path: '/bancos'
          },
          {
            id: '8fwkjfnnqnffwjdk',
            order: 6,
            disabled: false,
            name: 'Funcionários',
            path: '/funcionarios'
          },
        ]
      },
      {
        id: '6n4klm3dlkf',
        name: 'Conciliação Bancária',
        path: '/conciliacao-bancaria',
        disabled: false,
        order: 2,
      },
      {
        id: '6n4klm3dlkg',
        name: 'Títulos',
        path: '/titulos',
        disabled: false,
        order: 3,
      },
      {
        id: '6n4klm3dlkh',
        name: 'Movimentações de Depósitos',
        path: '/movimentacao-depositos',
        disabled: false,
        order: 4,
      },
      {
        id: '56nt5lr43emaccds',
        name: 'Condições de Pagamento',
        path: '/condicoes-de-pagamento',
        disabled: false,
        order: 5,
      },
      {
        id: '6n4klm3dlki',
        name: 'Relatórios',
        disabled: false,
        order: 6,
        children: [
          {
            id: '6n4klm3dlkj',
            disabled: false,
            order: 3,
            name: 'Títulos',
            path: '/relatorio-titulos'
          },
          {
            id: '6n4klm3dlkk',
            disabled: false,
            order: 2,
            name: 'Saldo de Conta',
          },
          {
            id: '6n4klm3dlkl',
            disabled: false,
            order: 1,
            name: 'Extrato de Conta',
          },
        ]
      },
      {
        id: '6n4klm3dlkm',
        name: 'Lançamento Financeiro',
        path: '/lancamento-financeiro',
        disabled: false,
        order: 7,
      },
    ]
  },
  {
    id: '6n54klm32wka',
    name: 'Frente de Loja',
    icon: IoStorefront,
    disabled: false,
    order: 8,
    children: [
      {
        id: '6n54klm32wkb',
        name: 'Cadastro',
        disabled: false,
        order: 1,
        children: [
          {
            id: '6n54klm32wkc',
            order: 2,
            disabled: false,
            name: 'Produtos',
            path: '/produtos',
          },
          {
            id: '6n54klm32wkd',
            order: 1,
            disabled: false,
            name: 'Clientes',
            path: '/clientes',
          },
        ]
      },
      {
        id: '6n54klm32wke',
        name: 'Condições de Recebimento',
        disabled: false,
        order: 2,
      },
      {
        id: '6n54klm32wkf',
        name: 'PDV',
        disabled: false,
        order: 3,
      },
      {
        id: '6n4klm3dlki',
        name: 'Relatórios',
        disabled: false,
        order: 4,
      },
    ]
  },
  {
    id: '0vshafsd1m2a',
    name: 'Fiscal',
    icon: RiFileList3Fill,
    disabled: false,
    order: 9,
    children: [
      {
        id: '6785493kfeafkmskdm',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          {
            id: '675843814412maksmdksam',
            name: 'CFOP',
            path: '/cfop',
            disabled: false,
            order: 1,
          },
          {
            id: 'ny5jn21k4ml890085784932',
            name: 'Procedências',
            path: '/procedencias',
            disabled: false,
            order: 2,
          },
          {
            id: 'ny5jn21k4ml8900gn5l4k2lk',
            name: 'Bases de Cálculo',
            path: '/bases-de-calculo',
            disabled: false,
            order: 3,
          },
          {
            id: 'ny5jn21k4ml8900gn4121klmfew',
            name: 'Códigos de Tributação',
            path: '/codigos-de-tributacao',
            disabled: false,
            order: 4,
          },
        ],
      },
      {
        id: '0vshafsd1m2b',
        name: 'Regras Fiscais',
        path: '/regras-fiscais',
        disabled: false,
        order: 1,
      },
      {
        id: '0vshafsd1m4122d',
        name: 'Regras de CFOP',
        path: '/regras-de-cfop',
        disabled: false,
        order: 2,
      },
      {
        id: '0vshafsd1m2c',
        name: 'Relatórios',
        disabled: false,
        order: 3,
        children: [
          {
            id: '0vshafsd1m2d',
            order: 1,
            disabled: false,
            name: 'Apuração de Impostos'
          },
          {
            id: '0vshafsd1m2e',
            order: 2,
            disabled: false,
            name: 'Registro de Entrada'
          },
          {
            id: '0vshafsd1m2f',
            order: 3,
            disabled: false,
            name: 'Registro de Saída'
          },
        ]
      },
      {
        id: '0vshafsd1m2d',
        name: 'Sequência do Cálculo',
        path: '/sequencia-calculo',
        disabled: false,
        order: 4,
      },
    ]
  },
  {
    id: '6nom3qe4tmk5jngvfmocd',
    name: 'Frota',
    icon: FaTruck,
    disabled: false,
    order: 10,
    children: [
      {
        id: '6785493kfeafkmskdm',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          {
            id: '56nt5lr43emn',
            order: 0,
            disabled: false,
            name: 'Centro de Custos',
            path: '/centro-de-custo'
          },
          {
            id: '6nom3qe4tmk5jngvfmocdramr',
            name: 'Marcas (Veículo)',
            path: '/marcas-veiculo',
            disabled: false,
            order: 1,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdodmelde',
            name: 'Modelos (Veículo)',
            path: '/modelos-veiculo',
            disabled: false,
            order: 2,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdoastbshta',
            name: 'Tipos de Combustível',
            path: '/tipos-de-combustivel',
            disabled: false,
            order: 3,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdodmotsmtosrs',
            name: 'Motoristas',
            path: '/motoristas',
            disabled: false,
            order: 4,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdodmelde',
            name: 'Veículos',
            path: '/veiculos',
            disabled: false,
            order: 5,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdabbastba',
            name: 'Abastecimento (Veículo)',
            path: '/abastecimentos-veiculo',
            disabled: false,
            order: 6,
          },
        ],
      },
      {
        id: '0vshafsd1m2c',
        name: 'Relatórios',
        disabled: false,
        order: 3,
        children: [
          {
            id: '6nom3qe4tmk5jngvfmocdodmotsmreklmr13m',
            name: 'Relatório de Motoristas',
            path: '/relatorio-motoristas',
            disabled: false,
            order: 1,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdodmotsmreklmr14m',
            name: 'Relatório de Veículos',
            path: '/relatorio-veiculos',
            disabled: false,
            order: 2,
          },
          {
            id: '6nom3qe4tmk5jngvfmocdodmotsmreklmr15m',
            name: 'Relatório de Abastecimentos',
            path: '/relatorio-abastecimentos',
            disabled: false,
            order: 3,
          },
        ]
      },
    ]
  },
  {
    id: 'n6lk5tn4rmlma',
    name: 'Patrimônio',
    icon: RiBuilding4Fill,
    disabled: false,
    order: 11,
    children: [
      {
        id: 'n6lk5tn4rmlmb',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          {
            id: 'n6lk5tn4rmlmc',
            disabled: false,
            order: 1,
            name: 'Imobilizados',
          },
          {
            id: 'n6lk5tn4rmlmd',
            disabled: false,
            order: 2,
            name: 'Lotes',
            path: '/lotes',
          },
        ]
      },
      {
        id: 'n6lk5tn4rmlme',
        name: 'Controle de Depreciação',
        disabled: false,
        order: 2,
      },
      {
        id: 'n6lk5tn4rmlmf',
        name: 'Tombamentos',
        disabled: false,
        order: 3,
      },
    ]
  },
  {
    id: '56nt5lr43emk',
    name: 'Vendas',
    icon: GiReceiveMoney,
    disabled: false,
    order: 12,
    children: [
      {
        id: '56nt5lr43eml',
        name: 'Cadastro',
        disabled: false,
        order: 1,
        children: [
          {
            id: '56nt5lr43emn',
            order: 1,
            disabled: false,
            name: 'Centro de Custos',
            path: '/centro-de-custo'
          },
          {
            id: '56nt5lr43emo',
            order: 3,
            disabled: false,
            name: 'Depósitos',
            path: '/depositos',
          },
          {
            id: '56nt5lr43emp',
            order: 2,
            disabled: false,
            name: 'Clientes',
            path: '/clientes',
          },
          {
            id: '56nt5lr43emq',
            order: 4,
            disabled: false,
            name: 'Estabelecimentos',
            path: '/estabelecimentos',
          },
          {
            id: '56nt5lr43emr',
            order: 5,
            disabled: false,
            name: 'Locais de Escrituração',
            path: '/locais-de-escrituracao',
          },
          {
            id: '56nt5lr43ems',
            order: 6,
            disabled: false,
            name: 'Metas de Vendas'
          },
          {
            id: '56nt5lr43emt',
            order: 9,
            disabled: false,
            name: 'Vendedores',
            path: '/vendedores',
          },
          {
            id: '56nt5lr43emtu',
            order: 7,
            disabled: false,
            name: 'Produtos',
            path: '/produtos',
          },
          {
            id: '56nt5lr43emtv',
            order: 8,
            disabled: false,
            name: 'Serviços'
          },
        ]
      },
      {
        id: '56nt5lr43emx',
        name: 'Precificação',
        path: '/regras-de-precificacao',
        disabled: false,
        order: 2,
      },
      {
        id: '56nt5lr43emy',
        name: 'Pedidos de Saída',
        path: '/pedidos-de-saida',
        disabled: false,
        order: 3,
      },
      {
        id: '56nt5lr43emz',
        name: 'Pedidos de Serviço',
        path: '/pedidos-de-venda-de-servico',
        disabled: false,
        order: 4,
      },
      {
        id: '56nt5lr43ema',
        name: 'Faturamento',
        path: '/faturamento',
        disabled: false,
        order: 5,
      },
      {
        id: '56nt5lr43emabjjjs',
        name: 'Condições de Pagamento',
        path: '/condicoes-de-pagamento',
        disabled: false,
        order: 6,
      },
      {
        id: '56nt5lr43emb',
        name: 'Relatórios',
        disabled: false,
        order: 6,
        children: [
          {
            id: '56nt5lr43emc',
            disabled: false,
            order: 1,
            name: 'Curva ABC',
          },
          {
            id: '56nt5lr43emd',
            disabled: false,
            order: 2,
            name: 'Faturamentos',
          },
          {
            id: '56nt5lr43eme',
            disabled: false,
            order: 3,
            name: 'Pedidos de Saída',
            path: '/relatorio-saida'
          },
          {
            id: '56nt5lr43emf',
            disabled: false,
            order: 4,
            name: 'Comissões',
          },
          {
            id: '56nt5lr43emg',
            disabled: false,
            order: 5,
            name: 'Metas de Vendas',
          },
        ]
      },
    ]
  },
  {
    id: 'n6lk5tn4r5dm4123',
    name: 'Gerenciamento',
    icon: RiTaskFill,
    disabled: false,
    order: 13,
    children: [
      {
        id: 'n6lk5tn4rm42411234',
        name: 'Cadastros',
        disabled: false,
        order: 1,
        children: [
          {
            id: 'n6lk5tn4rm424112345',
            name: 'Status de Tarefa',
            disabled: false,
            order: 1,
            path: '/status-de-tarefa',
          },
          {
            id: 'n6lk5tn4rm424112346',
            name: 'Tipos de Ticket',
            disabled: false,
            order: 2,
            path: '/tipos-de-ticket',
          },
          {
            id: 'n6lk5tn4rm424112347',
            name: 'Sprints',
            disabled: false,
            order: 3,
            path: '/sprints',
          },
          {
            id: 'n6lk5tn4rm424112348',
            name: 'Projetos',
            disabled: false,
            order: 4,
            path: '/projetos',
          },
          {
            id: 'n6lk5tn4rm424112349',
            name: 'Tickets',
            disabled: false,
            order: 5,
            path: '/tickets',
          },
        ],
      },
    ]
  },
  {
    id: 'n6lk5tn4r5dm4omowmo',
    name: 'Tickets',
    icon: IoTicket,
    disabled: false,
    order: 14,
    children: [
      {
        id: 'n6lk5tn4rm4241tkl3rd',
        name: 'Meus tickets',
        disabled: false,
        order: 1,
        path: '/tickets',
      },
    ]
  },
];