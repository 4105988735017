import React from 'react';
import { IoTicketOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom';
import { Container, Title, Card } from './styles';

const Home: React.FC = () => {
  const history = useHistory();

  function handleNewTicket() {
    return history.push('/tickets/inserir');
  }

  return (
    <Container>
      <Title>Bem-vindo(a)</Title>
      <Card onClick={handleNewTicket}>
        <h3>Criar novo ticket</h3>
        <div className="content">
          <IoTicketOutline style={{ fontSize: "5rem" }} />
        </div>
      </Card>
    </Container >
  );
}

export default Home;