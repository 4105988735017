import styled from 'styled-components';
import AsyncSelectLayout from 'react-select/async';

interface IToolTip {
  show: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: .2s;
`;

export const AsyncSelect = styled(AsyncSelectLayout)`
  & > div {
    box-shadow: none;
  }

  .css-319lph-ValueContainer{
    justify-items: flex-start;
  }

  & > div:nth-child(3) {
    min-width: 200px;
    width: 100%;
    padding: 0px 8px 0px 8px;
    background-color: transparent;
    white-space: nowrap;
    box-shadow: none;
    font-size: 12px;
    font-weight: 300;
    color: red;

    & > div {
      padding: 0;
    }

    &:focus {
      border: none;
    }
  }
`;

export const ToolTip = styled.p<IToolTip>`
  /* position: relative; */
  display: ${props => props.show ? 'inline-block' : 'none'};
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 2px 5px;

  /* Position the tooltip */
  position: absolute;
  top: -6px;
  z-index: 10;
`;