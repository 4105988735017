/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {Container, List, ListItem, ListLink, Button} from './styles';

import {IoIosArrowDown} from 'react-icons/io';

import {IMenuOption, menu as initialMenu} from './menu';
import { useMenu } from '../../contexts/menu';
import { useAuth } from '../../contexts/auth';
import useDetectClickOutside from '../../hooks/detectClickOutside';

export interface ILeftSidebarProps {
  isExpanded: boolean;
}
interface IExtensionOption {
  id: string;
  opened: boolean | null;
  children?: Array<IExtensionOption>;
}

export interface IListLinkProps {
  level?: number;
  selected?: boolean;
  expanded?: string | null;
}

const LeftSidebar: React.FC = () => {
  const { isLeftMenuOpen: isExpanded, setLeftMenuWidth: changeMenuWidth, toggleLeftMenu: toggleMenu } = useMenu();
  const history = useHistory();
  const location = useLocation();
  const {user} = useAuth();
  const menuRef = useRef<HTMLDivElement>(null);
  const [expansions, setExpansions] = useState<Array<IExtensionOption>>([]);
  const [menu, setMenu] = useState<Array<IMenuOption>>(initialMenu);

  useDetectClickOutside(menuRef, () => {
    if (isExpanded) {
      toggleMenu();
    }
  });

  useLayoutEffect(() => {
    if (menuRef.current) {
      changeMenuWidth(menuRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const baseOrderedMenu = menu.sort(compare);
    const secondOrderedMenu = baseOrderedMenu.map(menuOption => {
      if (!menuOption.disabled && menuOption.children) {
        const menuChildrenOrdered = menuOption.children.sort(compare);
        return {...menuOption, children: menuChildrenOrdered};
      } else {
        return menuOption;
      }
    });
    let thirdOrderedMenu = secondOrderedMenu.map(menuOption => {
      if (!menuOption.disabled && menuOption.children) {
        const menuChildrenOrdered = menuOption.children.map(secondMenuOption => {
          if (!secondMenuOption.disabled && secondMenuOption.children) {
            const secondMenuChildrenOrdered = secondMenuOption.children.sort(compare);
            return {...secondMenuOption, children: secondMenuChildrenOrdered};
          } else {
            return secondMenuOption;
          }
        })
        return {...menuOption, children: menuChildrenOrdered};
      } else {
        return menuOption;
      }
    });

    const clientModules = ['Tickets'];
    const adminModules = ['Home', 'Admin', 'Gerenciamento'];
    if (user && user.idClasse === '61cd2730ad47e0a02eab67d8') {
      thirdOrderedMenu = thirdOrderedMenu.filter(menuOption => clientModules.includes(menuOption.name));
    }
    if (user && user.idClasse !== '61cd2730ad47e0a02eab67d8') {
      thirdOrderedMenu = thirdOrderedMenu.filter(menuOption => adminModules.includes(menuOption.name));
    }
    
    setMenu(thirdOrderedMenu);
    handleExpansions(thirdOrderedMenu);
  }, []);

  const handleNavigate = (path: string | undefined) => {
    if (path === undefined) return;
    return history.push(path);
  } 

  const handleExpansions = (menuOptions: Array<IMenuOption>) => {
    const options: Array<IExtensionOption> = menuOptions.map(option => {
      if (option.children) {
        const secondMenuOptions = option.children.map(secondOption => {
          if (secondOption.children) {
            const thirdMenuOptions = secondOption.children.map(thirdOption => {
              return {id: thirdOption.id, opened: null};
            })
            return {id: secondOption.id, children: thirdMenuOptions, opened: false};
          } else {
            return {id: secondOption.id, opened: null}
          }
        });

        return {id: option.id, opened: false, children: secondMenuOptions};
      } else {
        return {id: option.id, opened: null};
      }
    });

    setExpansions(options);
  }

  const handleOpenMenu = (firstId: string | null = null, secondId: string | null = null) => {
    if (firstId) {
      let newExpansions;
      if (secondId) {
        newExpansions = expansions.map(option => {
          if (option.id === firstId) {
            if (option.children) {
              const newSecondExpansions = option.children.map(secondOption => {
                if(secondOption.id === secondId) {
                  return {...secondOption, opened: !secondOption.opened};
                } else {
                  return secondOption;
                }
              });
              return {...option, children: newSecondExpansions};
            } else {
              return option;
            }
          } else {
            return option;
          }
        });
      } else {
        newExpansions = expansions.map(option => {
          if (option.id === firstId) {
            if (option.children) {
              return {...option, opened: !option.opened};
            } else {
              return option;
            }
          } else {
            return option;
          }
        });
      }

      setExpansions(newExpansions);
    } else {
      return;
    }
  }

  const compare = (a: IMenuOption, b: IMenuOption): number => {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }
  
  const renderMenu = () => {
    if (expansions.length <= 0) {
      return;
    }

    const items = menu.map(({id, name, children, icon: Icon, disabled, path}, firstIndex) => (
      !disabled && (
        <ListItem key={id}>
          <ListLink
            title={name}
            expanded={String(isExpanded)}
            selected={path === location.pathname}
            onClick={isExpanded ? !children ? () => handleNavigate(path) : () => handleOpenMenu(id) : toggleMenu}>
            {Icon && <Icon />}
            {isExpanded && name}
            {isExpanded && children && <IoIosArrowDown className="arrow-down" />}
          </ListLink>
          {children && isExpanded && expansions[firstIndex].opened && (
            <List>
              {children.map((secondMenuOption, secondIndex) => (
                !secondMenuOption.disabled && (
                  <ListItem key={secondMenuOption.id}>
                    <ListLink
                      title={secondMenuOption.name}
                      onClick={!secondMenuOption.children ? () => handleNavigate(secondMenuOption.path) : () => handleOpenMenu(id, secondMenuOption.id)}
                      selected={secondMenuOption.path === location.pathname}
                      level={2} >
                      {secondMenuOption.name}
                      {isExpanded && secondMenuOption.children && <IoIosArrowDown className="arrow-down" />}
                    </ListLink>
                    {secondMenuOption.children && isExpanded && expansions[firstIndex]?.children?.[secondIndex].opened && (
                      <List>
                        {secondMenuOption.children.map(thirdMenuOption => (
                          !thirdMenuOption.disabled && (
                            <ListItem key={thirdMenuOption.id}>
                              <ListLink
                                title={thirdMenuOption.name}
                                onClick={() => handleNavigate(thirdMenuOption.path)}
                                selected={thirdMenuOption.path === location.pathname}
                                level={3} >
                                {thirdMenuOption.name}
                              </ListLink>
                            </ListItem>
                          )
                        ))}
                      </List>
                    )}
                  </ListItem>
                )
              ))}
            </List>
          )}
        </ListItem>
      )
    ))

    return items;
  }

  return (
    <Container isExpanded={isExpanded} ref={menuRef}>
      <List>
        {renderMenu()}
      </List>
      {/* {isExpanded && <Button variant="outlined">Configurar Menu</Button>} */}
    </Container>
  );
}

export default LeftSidebar;