import provider from './config';

interface ILogin {
  username: string;
  password: string;
}

interface IUserGroupPermissao {
  id: string;
  nome: string;
}

interface IClassePermissao {
  id: string;
  nome: string;
}

interface IPermission {
  id: number;
  classe: IClassePermissao;
  funcao: string;
  userGroup: IUserGroupPermissao;
  inactive: boolean;
  criadoEm: string;
  atualizadoEm: string;
}

interface IUserGroup {
  id: number;
  name: string;
  Permissoes: Array<IPermission>;

}

export interface IEntidade {
  id:  string | null;
  codigo:  string | null;
  situacao:  string | null;
  email: string;
  nome: string;
  foto: string | null;
  telefone:  string | null;
  celular:  string | null;
  cpfCnpj: string | null;
  cnpj:  string | null;
  dataNascimento:  string | null;
  genero:  string | null;
  banco:  string | null;
  agencia:  string | null;
  conta:  string | null;
  limiteCredito: string | null;
  bloqueadoEm:  string | null;
  negativado:  string | null;
  referenciaComercial:  string | null;
  endereco:  string | null;
  numeroEndereco:  string | null;
  bairro:  string | null;
  complemento:  string | null;
  UF?: IUF | null;
  Cidade?: ICidade;
  cep: string;
  inativo: boolean;
  criadoEm: string;
  atualizadoEm: string;
  idBloqueador: string;
  idLogin:  string | null;
  idClasse:  string | null;
  dUserGroupId: string;
  DUserGroup: IUserGroup;
  Estab: IEstab;
  idEstab: string;
  LocEscrit: any;
  token?: string;
}

interface IEstab {
  id?: string;
  nome?: string;
}

interface ICidade {
  id?: string;
  nome?: string;
}

interface IUF {
  id?: string;
  nome?: string;
}

interface ILoginResponse {
  token: string;
  entidade: IEntidade;
}

export const login = async ({username, password}: ILogin): Promise<IEntidade> => {
  try {
    const payload = {
      usuario: username,
      senha: password
    };

    const response = await provider.post('authentication/', payload);

    const userResponse: ILoginResponse = response.data;
    
    const user: IEntidade = {
      ...userResponse.entidade,
      token: userResponse.token,
    };
    return user;
  } catch (err: any) {
    throw Error("As credenciais utilizadas são inválidas, verifique-as novamente.")
  }
}