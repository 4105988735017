/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useFormik, FormikHelpers } from "formik";
import parseDate from "date-fns/parse";
import ptBR from "date-fns/locale/pt-BR";
import * as Validate from "validations-br";
import useFetch from "../../../hooks/useFetch";
import { IField } from "../../ListTable";
import { FormWrapper, Title, FormContent, Form, Button } from "../styles";
import {
  orderCols,
  adjustByRow,
  treatColField,
  formatCnpj,
  formatCpf,
  isObject,
} from "../../../utils/form";
import Toast from "../../Toast";
import Table from "../../Table";
import { isValidDate } from "../../../utils/date";
import { uploadFile } from "../../../services/upload";

import Input from "../../TextInput";

import FormInputs from "../../FormInputs";
import { getAddressCep } from "../../../services/address";
import toast from "react-hot-toast";
import { getProvider } from "../../../services/providers";
import { getResource } from "../../../services/resource";
interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  customFields?: any;
  editCustomFields?: any;
  filters: any;
  onSubmitCallback?(): void;
  modalItem?: any;
  getById?: boolean;
}

interface IState {
  item?: any;
}

const FormTemplate: React.FC<Props | any> = ({
  title,
  endpoint,
  customFields,
  editCustomFields,
  history,
  location,
  filters,
  onSubmitCallback,
  modalItem,
  getById,
}) => {
  const requestHandler = useFetch({
    endpoint,
  });
  const colsHandler = useFetch({
    endpoint: `${endpoint}/campos/`,
  });
  const [toEdit, setToEdit] = useState<any>(null);
  const [pinedClass, setPinedClass] = useState(false);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [fields, setFields] = useState<any>({});
  const [searchCEP, setSearchCEP] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [initialUploadStates, setInitialUploadStates] = useState<any>({});
  const [initialLookupStates, setInitialLookupStates] = useState<any>({});
  const [loadedLookups, setLoadedLookups] = useState(false);
  const [loadedUploads, setLoadedUploads] = useState(false);
  const [lookups, setLookups] = useState<any>({});
  const [uploads, setUploads] = useState<any>({});
  const [tables, setTables] = useState<any>({});
  const [userGroup, setUserGroup] = useState({
    comUserGroup: false,
    senha: "",
  });

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getData(true);
  }, [endpoint]);

  useEffect(() => {
    const keys = Object.keys(lookups);

    for (let key of keys) {
      if (key.toLowerCase().startsWith("uf") && lookups[key] && lookups[key].value) {
        setUfCidade(lookups[key].value);
      }
    }
  }, [lookups]);

  useEffect(() => {
    if (
      Object.keys(fields).length > 0 &&
      initialLookupStates &&
      !loadedLookups
    ) {
      if (Object.keys(initialLookupStates).length > 0) {
        pinClass("", false, initialLookupStates);
        setLoadedLookups(true);
      } else if (!pinedClass) {
        pinClass(filters.classe, false);
        setPinedClass(true);
      }
    }
  }, [fields, initialLookupStates]);

  useEffect(() => {
    if (
      Object.keys(fields).length > 0 &&
      initialUploadStates &&
      loadedLookups &&
      !loadedUploads
    ) {
      if (Object.keys(initialUploadStates).length > 0) {
        setUploadInitialState();
        setLoadedUploads(true);
      }
    }
  }, [fields, initialUploadStates, loadedLookups]);

  useEffect(() => {
    if (((location && location.state) || modalItem) && cols) {
      const state: any = {
        item: location?.state?.item || modalItem,
      };

      if (getById) {
        getItemById(modalItem.id);
      } else if (state.item) {
        const colsInitialValue: any = {};
        const orderedCols: any = cols.sort(orderCols);
        const lookupInitialState: any = {};
        const uploadInitialState: any = {};
        for (const col of orderedCols) {
          if (
            col.tipo === "texto" ||
            col.tipo === "select" ||
            col.tipo === "tabela"
          ) {
            colsInitialValue[col.campo] = state.item[col.campo];
          } else if (col.tipo === "lookup") {
            if (state.item[col.campo] && state.item[col.campo].id) {
              lookupInitialState[col.campo] = state.item[col.campo].id;
            }
          } else if (col.tipo === "upload") {
            if (state.item[col.campo]) {
              uploadInitialState[col.campo] = state.item[col.campo];
            }
          }
        }

        setInitialLookupStates(lookupInitialState);
        setInitialUploadStates(uploadInitialState);
        setInitialValues(colsInitialValue);
        setToEdit(state.item);
      }
    }
  }, [location, cols, modalItem]);

  useEffect(() => {
    if (cols) {
      adjustStartCols();
    }
  }, [cols]);

  useEffect(() => {
    if (modalItem) return;
    if (location && location.state) {
      const state: IState = location.state as IState;
      const keys = Object.keys(form.values);
      const value = Object.values(form.values);

      let cep: any;

      keys.find((item: any, index: any) =>
        item === "cep" ? (cep = value[index]) : ""
      );

      /* 
        a flag searchCEP aqui e usada para evitar que o front faça varios request 
        a cada input digitado quando o cep satisfaz a condição de request
      */
      if (cep && !cep.includes("_") && cep.length === 9) {
        if (state.item && state.item.cep && state.item.cep !== cep) {
          if (!searchCEP) {
            getAddress(cep);
            setSearchCEP(true);
          }
        } else if (!state.item.cep) {
          if (!searchCEP) {
            getAddress(cep);
            setSearchCEP(true);
          }
        }
      } else if (cep && cep.includes("_") && searchCEP) {
        setSearchCEP(false);
      }
    } else if (!location || !location.state) {
      const keys = Object.keys(form.values);
      const value = Object.values(form.values);

      let cep: any;

      keys.find((item: any, index: any) =>
        item === "cep" ? (cep = value[index]) : ""
      );

      if (cep && !cep.includes("_") && cep.length === 9 && !searchCEP) {
        getAddress(cep);
        setSearchCEP(true);
      } else if (cep && cep.includes("_") && searchCEP) {
        setSearchCEP(false);
      }
    }
  }, [location, form.values, modalItem]);

  async function getItemById(id: string) {
    const state: any = {};
    const colsInitialValue: any = {};
    const orderedCols: any = cols.sort(orderCols);
    const lookupInitialState: any = {};
    const uploadInitialState: any = {};

    try {
      if(endpoint === 'recursos'){
        const result = await getResource(id);
        state.item = result;
      } else {
        const result = await getProvider(id);
        state.item = result;
      }
    } catch (error: any) {
      Toast.show(error);
    }

    for (const col of orderedCols) {
      if (
        col.tipo === "texto" ||
        col.tipo === "select" ||
        col.tipo === "tabela"
      ) {
        colsInitialValue[col.campo] = state.item[col.campo];
      } else if (col.tipo === "lookup") {
        if (state.item[col.campo] && state.item[col.campo].id) {
          lookupInitialState[col.campo] = state.item[col.campo].id;
        }
      } else if (col.tipo === "upload") {
        if (state.item[col.campo]) {
          uploadInitialState[col.campo] = state.item[col.campo];
        }
      }
    }

    setInitialLookupStates(lookupInitialState);
    setInitialUploadStates(uploadInitialState);
    setInitialValues(colsInitialValue);
    setToEdit(state.item);
  }

  async function getAddress(value: string) {
    try {
      const _value = value.replace("-", "");
      const result = await getAddressCep(_value);
      if (result) {
        let byRow = { ...fields };
        let changed = false;
        for (const key of Object.keys(byRow)) {
          const value = byRow[key];

          byRow[key] = value.map((formField: any) => {
            if (
              (formField.id === "endereco" || formField.id === "bairro") &&
              !formField.isAddress
            ) {
              changed = true;
              return {
                ...formField,
                isAddress: true,
              };
            }

            if (formField.id.toLowerCase().startsWith("uf")) {
              changed = true;
              return {
                ...formField,
                initialState: { byName: result.uf },
              };
            }

            if (
              formField.id.toLowerCase().startsWith("cidade") ||
              formField.id === "LocalNascimento"
            ) {
              changed = true;
              return {
                ...formField,
                initialState: { byName: result.localidade },
              };
            }

            return formField;
          });
        }
        if (changed) setFields(byRow);
        setInitialValues({
          ...form.values,
          endereco: result.logradouro,
          bairro: result.bairro,
        });
      }
    } catch (error) {
      toast.error("Cep não encontrado.");
    }
  }

  function setUfCidade(id: string) {
    let byRow = { ...fields };
    for (const key of Object.keys(byRow)) {
      const value = byRow[key];

      byRow[key] = value.map((formField: any) => {
        if (
          formField.id.toLowerCase().startsWith("cidade") ||
          formField.id === "LocalNascimento"
        ) {
          return {
            ...formField,
            uf: id,
          };
        }

        return formField;
      });
    }

    setFields(byRow);
  }

  function adjustStartCols() {
    const colsField: any = {};
    const orderedCols: any = cols.sort(orderCols);

    for (const col of orderedCols) {
      const field = treatColField(
        col,
        handleChangeLookup,
        handleChangeUploads,
        handleChangeTables
      );

      if (field) {
        colsField[col.campo] = field;
      }
    }

    let byRow: any = adjustByRow(colsField);

    setFields(byRow);
  }

  async function getData(_firstRender = false) {
    const result = await colsHandler.get({
      ...filters,
      form: true,
    });

    const editableCols = result.filter((col: any) => col.editavel === true);
    setCols(editableCols);
  }

  function validate(values: any) {
    const requireFields = cols.filter((col) => col.required);
    const noRequireFields = cols.filter(col => !col.required);
    const errors: any = {};

    for (const _field of requireFields) {
      const { campo, nome, tipo } = _field;

      if (values.hasOwnProperty(campo)) {
        if (!values[campo]) {
          errors[campo] = `é obrigatório`;
        } else {
          let isValid;
          switch (campo) {
            case "cpfCnpj":
              if (values[campo].replace(/\D/g, "").length < 12) {
                isValid = Validate.validateCPF(values[campo]);
                if (!isValid) {
                  errors[campo] = "CPF inválido";
                }
              } else {
                isValid = Validate.validateCNPJ(values[campo]);
                if (!isValid) {
                  errors[campo] = "CNPJ inválido";
                }
              }

              break;
            case "cep":
              isValid = Validate.validateCep(values[campo]);
              if (!isValid) {
                errors[campo] = "CEP inválido";
              }
              break;
            case "email":
              isValid = Validate.validateEmail(values[campo]);
              if (!isValid) {
                errors[campo] = "E-mail inválido";
              }
              break;
            case "telefone":
            case "celular":
              isValid = Validate.validatePhone(values[campo]);
              if (!isValid) {
                errors[campo] = "Número inválido";
              }
              break;
            default:
              break;
          }
        }
      } else if (!lookups.hasOwnProperty(campo) && tipo === "lookup") {
        errors[campo] = `é obrigatório`;
        Toast.show(`Insira ${nome}`);
      } else if (_field.tipo === "tabela") {
        if(tables.hasOwnProperty(campo) && tables[campo].length < 1) {
          Toast.show(`Insira ${nome}`);
          errors[campo] = `é obrigatório`;
        }
      } else {
        if (_field.tipo !== "lookup") {
          errors[campo] = `é obrigatório`;
        }
      }
    }

    for (const _field of noRequireFields) {
      const { campo } = _field;

      if (values.hasOwnProperty(campo)) {
        if (!values[campo]) {
          
        } else {
          let isValid;
          switch (campo) {
            case "email":
              isValid = Validate.validateEmail(values[campo]);
              if (!isValid) {
                errors[campo] = "E-mail inválido";
              }
              break;
            default:
              break;
          }
        }
      }
    }

    return errors;
  }

  function handleChangeTables(field: string, value: any) {
    return setTables((prevState: any) => {
      return { ...prevState, [field]: value };
    });
  }

  function handleChangeLookup(field: string, options: any) {
    return setLookups((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  }

  function handleChangeUploads(field: string, options: any) {
    return setUploads((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  }

  function setUploadInitialState() {
    let byRow = { ...fields };
    let keys = Object.keys(initialUploadStates);

    for (const key of Object.keys(byRow)) {
      const value = byRow[key];
      byRow[key] = value.map((formField: any) => {
        if (keys.includes(formField.id)) {
          let initialState = {};
          if (initialUploadStates[formField.id]) {
            initialState = initialUploadStates[formField.id];
          }
          return {
            ...formField,
            initialState,
          };
        }

        return formField;
      });
    }

    setFields(byRow);
  }

  function pinClass(
    colValue?: string,
    disabled?: boolean,
    multiple: any | null = null
  ) {
    let byRow = { ...fields };
    let keys: string[];

    if (multiple) keys = Object.keys(multiple);
    for (const key of Object.keys(byRow)) {
      const value = byRow[key];
      let found = false;
      byRow[key] = value.map((formField: any) => {
        if (multiple) {
          if (keys.includes(formField.id)) {
            let initialState = {};
            if (multiple[formField.id]) {
              initialState = { byId: multiple[formField.id] };
            }
            return {
              ...formField,
              disabled,
              initialState,
            };
          }
        } else {
          if (formField.id === "Classe") {
            found = true;
            let initialState = {};

            if (colValue) {
              initialState = { byName: colValue };
            }

            return {
              ...formField,
              disabled,
              initialState,
            };
          }
        }

        return formField;
      });

      if (found) {
        break;
      }
    }

    setFields(byRow);
  }

  function handleChangeUserGroup(field: string, value: string | boolean) {
    return setUserGroup({ ...userGroup, [field]: value });
  }

  async function handleSubmit(values: any, helpers: FormikHelpers<any>) {
    let payload = {
      ...values,
    };

    const hasCodigo = cols.filter((col) => col.campo === "codigo")[0];
    const hasNome = cols.filter((col) => col.campo === "nome")[0];
    const hasClasse = cols.filter((col) => col.campo === "Classe")[0];

    if (hasCodigo && (!payload.codigo || payload.codigo === "")) {
      return Toast.show("Insira o código");
    }

    if (hasNome && (!payload.nome || payload.nome === "")) {
      return Toast.show("Insira o nome");
    }

    if (lookups) {
      const keys = Object.keys(lookups);

      for (const key of keys) {
        const idKey = `id${key}`;
        if (lookups[key] === null) {
          payload = {
            ...payload,
            [idKey]: null,
          };
        } else if (Array.isArray(lookups[key])) {
          payload = {
            ...payload,
            [key]: lookups[key].map((item: any) => item.value),
          };
        } else {
          const idKey = `id${key}`;
          payload = {
            ...payload,
            [idKey]: lookups[key].value,
          };
        }
      }
    }

    if (hasClasse && (!payload.idClasse || payload.idClasse === "")) {
      return Toast.show("Insira a classe");
    }

    if (userGroup.comUserGroup) {
      payload = {
        ...payload,
        ...userGroup,
      };
    }
    let adjustedPayload: any = {};

    for (const key of Object.keys(payload)) {
      const value = payload[key];
      const isDate = isValidDate(value);
      if (isDate) {
        adjustedPayload = {
          ...adjustedPayload,
          [key]: parseDate(
            value,
            isDate === "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm",
            new Date(),
            { locale: ptBR }
          ),
        };
      } else {
        if (key === "cpfCnpj") {
          if (value === null || value === "") {
            adjustedPayload[key] = null;
          } else {
            let adjustedValue = value.replace(/\D/g, "");
            if (adjustedValue.length < 12) {
              adjustedValue = formatCpf(adjustedValue);
            } else {
              adjustedValue = formatCnpj(adjustedValue);
            }
            adjustedPayload[key] = adjustedValue;
          }
        } else {
          adjustedPayload[key] = value === "" ? null : value;
        }
      }
    }

    if (userGroup.comUserGroup) {
      const { idClasse, ...data } = adjustedPayload;

      if (data.senha && data.senha.length < 8) {
        return Toast.show("A senha precisa ter ao menos 8 dígitos", "error");
      }

      adjustedPayload = data;
    }

    if (uploads) {
      const keys = Object.keys(uploads);

      for (const key of keys) {
        const value = uploads[key];
        const formData = new FormData();
        formData.set("file", value);
        try {
          const url = await uploadFile(formData);
          adjustedPayload[key] = url;
        } catch (err: any) {
          Toast.show(err.message, "error");
        }
      }
    }

    if (tables && Object.keys(tables).length > 0) {
      const keys = Object.keys(tables);

      for (const key of keys) {
        adjustedPayload = {
          ...adjustedPayload,
          [key]: tables[key],
        };
      }
    }

    if (location && location.state) {
      //se estiver editando verificar os campos editados.
      const { item } = location.state as IState;
      for (const key of Object.keys(item)) {
        if (Array.isArray(item[key]) || isObject(item[key])) {
          //verifica se o campo atual e um object ou array ou undefined (nao foi alterado)
          continue;
        } else {
          if (adjustedPayload[key] === item[key]) {
            //se houve modificação no campo adicionar no payload para ser enviado.
            delete adjustedPayload[key];
          }
        }
      }
    } else {
      for (const key of Object.keys(adjustedPayload)) {
        if (adjustedPayload[key] === null) {
          delete adjustedPayload[key];
        }
      }
    }

    try {
      if ((editCustomFields && editCustomFields.edit) || modalItem) {
        const response = await requestHandler.put(
          `/${toEdit.id}`,
          adjustedPayload
        );
        if (!response.error) {
          if (history) {
            history.goBack();
          } else {
            onSubmitCallback(response);
          }
          helpers.setSubmitting(false);
        }
      } else {
        const response = await requestHandler.post(adjustedPayload);
        if (!response.error) {
          if (history) {
            history.goBack();
          } else {
            onSubmitCallback(response);
          }
          helpers.setSubmitting(false);
        }
      }
    } catch (err: any) {
      helpers.setSubmitting(false);
    }
  }

  return (
    <FormWrapper>
      {history ? (
        <Title>
          {title} {toEdit && toEdit.id && <span>(ID {toEdit.id})</span>}
        </Title>
      ) : null}
      <FormContent>
        <Form onSubmit={form.handleSubmit} id="custom-form">
          <FormInputs fields={fields} form={form} />
          {customFields.userGroup && (
            <>
              <Input
                id="comUserGroup"
                name="comUserGroup"
                label="Com login"
                checked={userGroup.comUserGroup}
                type="checkbox"
                onChange={({ target: { value } }) =>
                  handleChangeUserGroup("comUserGroup", value)
                }
              />
              {userGroup.comUserGroup && (
                <Input
                  id="senha"
                  name="senha"
                  label="Senha"
                  value={userGroup.senha}
                  type="password"
                  onChange={({ target: { value } }) =>
                    handleChangeUserGroup("senha", value)
                  }
                />
              )}
            </>
          )}
          {editCustomFields &&
            toEdit &&
            Object.keys(editCustomFields).map((key: string) => {
              if (key !== "edit") {
                if (editCustomFields[key].type === "table") {
                  return (
                    <Table
                      title={editCustomFields[key].label}
                      cols={editCustomFields[key].cols}
                      item={toEdit}
                      loadState={editCustomFields[key].loadState}
                      styles={{ paddingTop: 12 }}
                    />
                  );
                }
              }
            })}
          <Button disabled={form.isSubmitting} variant="filled" type="submit">
            Salvar
          </Button>
        </Form>
      </FormContent>
    </FormWrapper>
  );
};

export default FormTemplate;
